/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import MediaItem from './MediaItem.js'

const getWidth = () => window.innerWidth

const ItemPartPage = ({ libRecommendations , firstItemIndex , showDetailedItem , onWheel, setActiveIndex , isActive , isVisable , determinePlacement , itemHeight , i}) => {
    
return (

  <div className='itemContainer'>

  <div 

      onWheel = {(e) => onWheel(e)}
      onClick={() => showDetailedItem(libRecommendations[firstItemIndex])}
      className = {"carousel-item" + (isActive(i) ? " active" : "") +  (isVisable(i) ? " visible" : "")}
      key={i}
      style={{
        transform: `translateY(${determinePlacement(i)}px)`,
        transitionDuration: "1s",

        height: itemHeight + "px",
        top: 0,
        width: "100vw",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        overflow: "hidden",
        color: "black"

      }}
      >
      <div className='itemPartPage'>
      
          <div className='itemTitleText itemTitleText2Left'> {libRecommendations[firstItemIndex].title}
          <span className="mediaText"> {libRecommendations[firstItemIndex].media}</span>
          </div>
          

          <MediaItem item={libRecommendations[firstItemIndex]} className ="TowItemsPageImage"></MediaItem>


      </div>

  </div>
</div>


   )

}



export default ItemPartPage
