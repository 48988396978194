/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import MediaItem from './MediaItem.js'

const getWidth = () => window.innerWidth

const ItemShelfPage = ({ bookShelf , user , firstItemIndex , showHomePage , onWheel, setActiveIndex , isActive , isVisable , determinePlacement , itemHeight , i}) => {
    
return (

    <div className='itemContainer'>

        <div 

            onWheel = {(e) => onWheel(e)}
            className = {"carousel-item" + (isActive(i) ? " active" : "") +  (isVisable(i) ? " visible" : "")}
            key={i}
            style={{
              transform: `translateY(${determinePlacement(i)}px)`,
              transitionDuration: "1s",
              height: itemHeight + "px",
              top: 0,
              width: "100vw",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              overflow: "hidden",
              color: "black"

            }}
            >


            <div className="userShelfContainer">
                <div className="userImageContainer">
                    <img className="userImageClass" src={user.userImageUrl}/>
                </div>

                <div className="userInfoContainer">
                    <div className="userName"> {user.name} </div>
                    <div className="follow-container"> Follow </div>
                </div>

                <div className="clearAllAll"> </div>
                
            </div>

            <div className="bookShelfImagesContainer">

                {bookShelf.map((bookShelfObj, i) => 

                    <div className="bookShelfImageContainer">
                        <MediaItem item={bookShelfObj} className ="TowItemsPageImage"></MediaItem>
                    </div>

                )}
            </div>


        </div>
    </div>


   )

}



export default ItemShelfPage
