/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import LoadUsersDataFromDB from './LoadUsersDataFromDB'
import HomePage from './HomePage.js'
import ItemDetails from './ItemDetails.js'
import VerticalCarousel from './VerticalCarousel.js'
import { projectFirestore} from './firebase/config';

import XMLParser from "fast-xml-parser/src/xmlparser/XMLParser.js";


function App() {

 const [itemsData, setItemsData] = useState([]);
 const [profilesTagsArray, setProfilesTagsArray] = useState([]);
 const [usersArray, setUsersArray] = useState([]);
 const [userProfileTagsArray, setUserProfileTagsArray] = useState([]);
 const [user, setUser] = useState([]);
 const [viewStage, setViewStage] = useState("showHomePage");
 const [selectedTagName, setSelectedTagName] = useState("");
 const [libRecommendations, setLibRecommendations] = useState([]);
 const [bookShelf, setBookShelf] = useState([]);
 const [libNotable, setLibNotable] = useState([]);
 const [context, setContext] = useState("");
 const [mouseEnableClickIndex, setMouseEnableClickIndex] = useState(-1);
 const [detailedItem, setDetailedItem] = useState({});

 
 const [currntUserIndex, setCurrntUserIndex] = useState(null);
 const [selectedItems, setSelectedItems] = useState([]);
 const [homePageImages , setHomePageImages] = useState([
      {id:"topic-feature__figure-1"} ,
      {id:"topic-feature__figure-2"},
      {id:"topic-feature__figure-3"},
      {id:"topic-feature__figure-4"},
      {id:"topic-feature__figure-5"}]);

const [matches, setMatches] = useState(
  window.matchMedia("(min-width: 800px)").matches
)

/*
 // ===================================================================================================
    const handleScroll = (e) => {

  // ===================================================================================================
  if (window.innerHeight + e.target.documentElement.scrollTop + 1 >= e.target.documentElement.scrollHeight) {
//    setSelectedItems(prevSelectedItems => [...(prevSelectedItems.shift()), prevSelectedItems[0]]);
//    setSelectedItems(prevSelectedItems => [(prevSelectedItems.shift()), prevSelectedItems[0]]);
//   setSelectedItems(prevSelectedItems => prevSelectedItems.push(prevSelectedItems.shift()));

//setSelectedItems(prevSelectedItems => [...prevSelectedItems , prevSelectedItems.shift()]);
// setSelectedItems(prevSelectedItems => [prevSelectedItems.push(prevSelectedItems.shift())])

setSelectedItems(prevSelectedItems => {
  console.log("handleScroll prevSelectedItems = " ,  prevSelectedItems);
  var newArray = [...prevSelectedItems];
  let firstItem = newArray[0];
  newArray.shift();
  newArray.push(firstItem);
  console.log("handleScroll newSelectedItems = " ,  newArray);
  return newArray;
})


    //let newSelectedItems = [...selectedItems];
  //  newSelectedItems.push(newSelectedItems.shift());
 //   setSelectedItems(newSelectedItems);

  //  const newSelectedItems = [...selectedItems];
 //   newSelectedItems.push(newSelectedItems.shift());
  //  setSelectedItems(newSelectedItems);
  }
 } */
 // ===================================================================================================
  useEffect(() => {
// ===================================================================================================

    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  /*
 // ===================================================================================================
  useEffect(() => {
// ===================================================================================================
  window.addEventListener('scroll' , handleScroll)
 
}, []); */
  

 // ===================================================================================================
    const loadManifestData = (manifestRequest , resp1) => {

 // ===================================================================================================
  // console.log("resp1 = ", resp1);
  const getMatchPhotoManifest =  fetch(manifestRequest , {
    method: 'GET',
  })    
  .then(resp => resp.json())
  //   .then(resp=> setNliSearchResults(resp))
  .then(resp=> {
        console.log(resp) ; 
        const imageUrl = resp.sequences[0].canvases[0].images[0].resource["@id"] ; 
        setItemsData((prevItemsData) => [
          ...prevItemsData,
          {
              imageUrl: imageUrl,
              title: resp.metadata[0].value,
              fullScreen: false
          },
      ]);
  } )
  .catch(error=>console.log(error))

 }


   // ===================================================================================================
   const loadMarktData = (markPath , resp1) => {
    // ===================================================================================================
     const getMatchPhotoManifest =  fetch("http://localhost:3000/mark/" + markPath) 
 //    .then(resp => resp.json())
     //   .then(resp=> setNliSearchResults(resp))
     .then(resp=> {
           console.log(resp) ; 
     } )
     .catch(error=>console.log(error))
   
    }




// ===================================================================================================
  const getUserProfileTagsArray = () => {
// ===================================================================================================

  let userProfile = usersArray[currntUserIndex].profile;
  let theUerProfileTagsArray = [];
  for (let userProfileIndex = 0; userProfileIndex < userProfile.length ; userProfileIndex++) {
    const userProfileTagId = userProfile[userProfileIndex];
    for (let profilesTagsIndex = 0; profilesTagsIndex < profilesTagsArray.length ; profilesTagsIndex++) {

      if (userProfileTagId == profilesTagsArray[profilesTagsIndex].id) {
        theUerProfileTagsArray.push(profilesTagsArray[profilesTagsIndex]);
        break;
      }
    }
  }
  return theUerProfileTagsArray
  } 

  // ===================================================================================================
  const getUserObj = () => {
    // ===================================================================================================
    
      let userObj = usersArray[currntUserIndex];
      return userObj;
    
    } 



// ===================================================================================================
const showHomePage = () => {
  // ===================================================================================================
  setViewStage("showHomePage")
}

// ===================================================================================================
const showDetailedItem = (item) => {
  // ===================================================================================================

 if (Object.hasOwn(item, 'likedBy')) {
  setDetailedItem(item);
  setViewStage("showDetailedItem")

 }

}

// ===================================================================================================
const showVerticalCarousel = (item) => {
  // ===================================================================================================

  setViewStage("showVerticalCarousel")
}


// ===================================================================================================
  const handleClick = (e) => {
// ===================================================================================================
const fetchData1 = async () => {

    let index = parseInt(e.target.id);
        
    if (isNaN(e.target.id))
    {
      return;
    }

    if (mouseEnableClickIndex != index) {
        document.getElementById(e.target.id).classList.add("disabeSelectionClass");
  
      return
    }

    let tagName = userProfileTagsArray[index].tagName;
    setSelectedTagName(tagName);

    // Load libRecommendations for DB
    // ================================
    const querySnapshot3 = await projectFirestore.collection("libRecommendations").where('profileTagName', '==', tagName).where('context', '==', context).get();
    let libRecommendations1 =[];
    querySnapshot3.forEach((doc) => {
      libRecommendations1.push({...doc.data() ,id: doc.id});
     });

     let libRecommendations2 =[];

     for (let i = 0 ; i < libRecommendations1.length ; i++) {

      // for book:
      //==============
      if (!libRecommendations1[i].media.localeCompare("book")) {
        let manifestRequest = "https://iiif.nli.org.il/IIIFv21/" + libRecommendations1[i].manifest + "/manifest"
        const manifest = await fetch(manifestRequest);
        const manifestJson = await manifest.json();

        // save high res image path
        // ==========================
        let members = manifestJson.structures[0].members;
        let bookPagesUrls = "";

        for (let imageIndex = 0 ; imageIndex < 10 ; imageIndex++) {
          let bookPageUrl = members[imageIndex]["@id"];
          bookPageUrl = bookPageUrl.concat("/full/max/0/default.jpg");
          if (imageIndex == 0) {
            bookPagesUrls = bookPagesUrls.concat(bookPageUrl);
          } else {
            bookPagesUrls = bookPagesUrls.concat("," + bookPageUrl);
          }
        }

        //let itemIFrame = "http://localhost:8000/BookReaderDemo/demo-embed-iframe-params-src.html?images=" + bookPagesUrls;
        let itemIFrame = "http://booktrail.megama.net/BookReaderDemo/demo-embed-iframe-params-src.html?images=" + bookPagesUrls;

        libRecommendations2.push({...libRecommendations1[i] ,itemIFrame: itemIFrame});
      } else {
        libRecommendations2.push(libRecommendations1[i]);
      }

    }
    setLibRecommendations(libRecommendations2);

   // console.log("aa = " , libRecommendations1)

    setViewStage("showVerticalCarousel")
}

    // call the function
    fetchData1();

  }

  // ===================================================================================================
const handleMouseEnterIndex = (index , id) => {
  // ===================================================================================================


    console.log("handleMouseEnterIndex index = " , index);
    console.log("handleMouseEnterIndex id = " , id);

    // remove 'textUnderline' class from all elements
    // ===============================================
    Array.from(document.querySelectorAll('textUnderline')).forEach(function(el) { 
      el.classList.remove('textUnderline');
    });

    Array.from(document.querySelectorAll('.disabeSelectionClass')).forEach(function(el) { 
      el.classList.remove('disabeSelectionClass');
    });

  
    // add 'textUnderline' class to 'MouseEnter' element
    // ===================================================
    document.getElementById(id).classList.add("textUnderline");




    let oldHomePageImages =  [{id:"topic-feature__figure-1"} ,
    {id:"topic-feature__figure-2"},
    {id:"topic-feature__figure-3"},
    {id:"topic-feature__figure-4"},
    {id:"topic-feature__figure-5"}]

    if (index == 0) {
      setHomePageImages(oldHomePageImages);
    } else {
        let newHomePageImages = [];
        for (let i = 0 ; i < index  ; i++) {
            newHomePageImages.push(oldHomePageImages[i + 1]);
        } 
        newHomePageImages.push(oldHomePageImages[0]);

        for (let i = index + 1; i < 5  ; i++) {
          newHomePageImages.push(oldHomePageImages[i]);
      } 

      setHomePageImages(newHomePageImages);
    }
}


  // ===================================================================================================
  const handleMouseEnter = (e) => {
  // ===================================================================================================
    let index = parseInt(e.target.id);
    
    if (isNaN(e.target.id) || e.target.id == "")
    {
      return;
    }
    console.log("handleMouseEnter , e.target.id = " , e.target.id);

    handleMouseEnterIndex(index , e.target.id);


  }

  // ===================================================================================================
  const handleMouseLeave = (e) => {
    // ===================================================================================================
    
    if (isNaN(e.target.id)  || e.target.id == "")
    {
      return;
    }
    console.log("handleMouseLeave e.target.id = " , e.target.id);

        // remove 'textUnderline' class from all elements
    // ===============================================
    Array.from(document.querySelectorAll('.textUnderline')).forEach(function(el) { 
      el.classList.remove('textUnderline');
    });

  }
  
  // ===================================================================================================
  const parseMarkData = (markObj) => {
    // ===================================================================================================

    let makeData = {};

    // get high res file name - tag=907
    // ==================================
    let dataFiled = markObj.searchRetrieveResponse.records.record.recordData.record.datafield;

    let datafieldIndex = 0;
    let notFound = true;
    makeData.highResName = "";
    for (; datafieldIndex < dataFiled.length && notFound; datafieldIndex++) {
      notFound = dataFiled[datafieldIndex]["@_tag"].localeCompare("907");
    }
    if (!notFound) {
      let subfield = dataFiled[--datafieldIndex].subfield;
      let subfieldIndex = 0;
      for (notFound = true; subfieldIndex < subfield.length && notFound; subfieldIndex++) {
        if (!subfield[subfieldIndex]["@_code"].localeCompare("d")) {
          makeData.highResName = makeData.highResName.concat(subfield[subfieldIndex]["#text"]);
          break;
        }
      }
    }

    // get personal information - tag=100
    // ==================================
    datafieldIndex = 0;
    notFound = true;
    makeData.personalInfo = "";
    for (; datafieldIndex < dataFiled.length && notFound; datafieldIndex++) {
      notFound = dataFiled[datafieldIndex]["@_tag"].localeCompare("100");
    }
    if (!notFound) {
      let subfield = dataFiled[--datafieldIndex].subfield;
      let subfieldIndex = 0;
      for (notFound = true; subfieldIndex < subfield.length ; subfieldIndex++) {
        if (!subfield[subfieldIndex]["@_code"].localeCompare("a") || !subfield[subfieldIndex]["@_code"].localeCompare("d")  || !subfield[subfieldIndex]["@_code"].localeCompare("e")) {
          makeData.personalInfo = makeData.personalInfo.concat(subfield[subfieldIndex]["#text"]);
        }
      }
    }
      
    // get title information - tag=245
    // ==================================
    datafieldIndex = 0;
    notFound = true;
    makeData.titleInfo = "";
    for (; datafieldIndex < dataFiled.length && notFound; datafieldIndex++) {
      notFound = dataFiled[datafieldIndex]["@_tag"].localeCompare("245");
    }
    if (!notFound) {
      let subfield = dataFiled[--datafieldIndex].subfield;

      if (Array.isArray(subfield)) {

        let subfieldIndex = 0;
        for (notFound = true; subfieldIndex < subfield.length ; subfieldIndex++) {
          if (!subfield[subfieldIndex]["@_code"].localeCompare("a")) {
            makeData.titleInfo = makeData.titleInfo.concat(subfield[subfieldIndex]["#text"]);
            break
          }
        }
      } else {
        if (!subfield["@_code"].localeCompare("a")) {
          makeData.titleInfo = makeData.titleInfo.concat(subfield["#text"]);
        }

      }
    }
  
    // get title information - tag=260
    // ==================================
    datafieldIndex = 0;
    notFound = true;
    makeData.date = "";
    for (; datafieldIndex < dataFiled.length && notFound; datafieldIndex++) {
      notFound = dataFiled[datafieldIndex]["@_tag"].localeCompare("260");
    }
    if (!notFound) {
      let subfield = dataFiled[--datafieldIndex].subfield;
      let subfieldIndex = 0;

      if (Array.isArray(subfield)) {
        for (notFound = true; subfieldIndex < subfield.length ; subfieldIndex++) {
          if (!subfield[subfieldIndex]["@_code"].localeCompare("g")) {
            makeData.date = makeData.date.concat(subfield[subfieldIndex]["#text"]);
            break
          }
        }
      } else {
        if (!subfield["@_code"].localeCompare("g")) {
          makeData.date = makeData.date.concat(subfield["#text"]);
        }
      }
    }

    // get title information - tag=597
    // ==================================
    datafieldIndex = 0;
    notFound = true;
    makeData.notes = "";
    for (; datafieldIndex < dataFiled.length && notFound; datafieldIndex++) {
      notFound = dataFiled[datafieldIndex]["@_tag"].localeCompare("597");
    }
    if (!notFound) {
      let subfield = dataFiled[--datafieldIndex].subfield;
      let subfieldIndex = 0;
      if (Array.isArray(subfield)) {

        for (notFound = true; subfieldIndex < subfield.length ; subfieldIndex++) {
          if (!subfield[subfieldIndex]["@_code"].localeCompare("a")) {
            makeData.notes = makeData.notes.concat(subfield[subfieldIndex]["#text"]);
          }
        }
      } else {
        if (!subfield["@_code"].localeCompare("a")) {
          makeData.notes = makeData.notes.concat(subfield["#text"]);
        }
      }
    }

    // 710 - Added Entry-Corporate Name 
    // ==================================
    datafieldIndex = 0;
    notFound = true;
    makeData.corporateName = "";
    for (; datafieldIndex < dataFiled.length && notFound; datafieldIndex++) {
      notFound = dataFiled[datafieldIndex]["@_tag"].localeCompare("710");
    }
    if (!notFound) {
      let subfield = dataFiled[--datafieldIndex].subfield;
      let subfieldIndex = 0;
      if (Array.isArray(subfield)) {

        for (notFound = true; subfieldIndex < subfield.length ; subfieldIndex++) {
          if (!subfield[subfieldIndex]["@_code"].localeCompare("a")) {
            makeData.corporateName = makeData.corporateName.concat(subfield[subfieldIndex]["#text"]);
          }
        }
      } else {
        if (!subfield["@_code"].localeCompare("a")) {
          makeData.corporateName = makeData.corporateName.concat(subfield["#text"]);
        }
      }
    }

    return makeData;
  } 
    
  /*
  // ===================================================================================================
  useEffect(() => {
    // ===================================================================================================
   
  if (userProfileTagsArray.length != 0) {
    if (context != "") {
      if (!context.localeCompare("Bialik")) {
        handleMouseEnterIndex(1 , '1');
      } else {
        handleMouseEnterIndex(2 , '2');
      } 
    }

  }
  }, [userProfileTagsArray]) */



// ===================================================================================================
  useEffect(() => {
 // ===================================================================================================
    const fetchData = async () => {

      const queryParams = new URLSearchParams(window.location.search)
      let selectedContext = queryParams.get("search")
      if (selectedContext == "" || selectedContext == null) {
        setContext ("Bialik");
      } else {
        if (!selectedContext.localeCompare("El%20Al") || !selectedContext.localeCompare("El Al")) {
          setContext("El Al");
        } else {
          setContext("Bialik");
        }
  
      }


      if (currntUserIndex != null ) {

  
        const theUserProfileTagsArray = getUserProfileTagsArray();
        setUserProfileTagsArray(theUserProfileTagsArray);

        const theUserObj = getUserObj();
        setUser(theUserObj);

        // Load data from bookShelf
        // =========================
        const querySnapshot = await projectFirestore.collection("bookShelf").get();
        let theBookShelf =[];
        querySnapshot.forEach((doc) => {
          theBookShelf.push({...doc.data() ,id: doc.id});
         });
         setBookShelf(theBookShelf);
    
        // Load data from libNotable
        // ============================
        const querySnapshot1 = await projectFirestore.collection("libNotable").get();
        let theLibNotable =[];
        querySnapshot1.forEach((doc) => {
          theLibNotable.push({...doc.data() ,id: doc.id});
         });
         setLibNotable(theLibNotable);
  
        // Search for items in library
        // ============================
        let materialType = "book";
        let tagName = "ביאליק"
        const resp =  await fetch("https://api.nli.org.il/openlibrary/search?api_key=0x2IDGriWUtLzHybO2TlYgW7otmVvWcUvav3q1Cj&query=title,contains," + 
                                  tagName + "&availability_type=online_access&material_type=" + materialType , {
                                    method: 'GET',
                                  })
        const searchResults = await resp.json(); 
        console.log("searchResults = ",searchResults);

        let numSelectedItems = 0;

        // Collect books
        // ===============
        if (!materialType.localeCompare("book")) {
          for (let i = 0 ; numSelectedItems <= 5 && i < searchResults.length; i++) {

            // For each element from searchResults (if not online_in_library_only) load its MARK file to get its item params
            // ================================================================================================================
            if (!searchResults[i]["http://purl.org/dc/elements/1.1/accessRights"][0]["@value"].localeCompare("online_and_api_access") ) {

              let manifestRequest = searchResults[i]["http://purl.org/dc/elements/1.1/relation"][0]["@id"]
              const manifest = await fetch(manifestRequest);
              const manifestJson = await manifest.json();


              // Api for MARK file
              // ==================
              const docId = searchResults[i]["http://purl.org/dc/elements/1.1/recordid"][0]["@value"]
              let markXmlPath = "https://eu01.alma.exlibrisgroup.com/view/sru/972NNL_INST?version=1.2&operation=searchRetrieve&recordSchema=marcxml&query=alma.mms_id=" + docId;
              const response = await fetch(markXmlPath)
              const xmlDataText = await response.text();
  //           console.log("xmlDataText = " , xmlDataText)

              // use XMLParser library object to conver XML to json object (include attributes (tags etc.))
              // ===========================================================================================
              const options = {
                ignoreAttributes : false
              };
              const parser = new XMLParser(options);
              let markObj= parser.parse(xmlDataText);
  //           console.log("markObj = " , markObj);

              // Get relavant mark data
              // =========================
              let selectedItem = parseMarkData(markObj);

              // save high res image path
              // ==========================
              let members = manifestJson.structures[0].members;
              let bookPagesUrls = "";

              for (let imageIndex = 0 ; imageIndex < 10 ; imageIndex++) {
                let bookPageUrl = members[imageIndex]["@id"];
                bookPageUrl = bookPageUrl.concat("/full/max/0/default.jpg");
                if (imageIndex == 0) {
                  bookPagesUrls = bookPagesUrls.concat(bookPageUrl);
                } else {
                  bookPagesUrls = bookPagesUrls.concat("," + bookPageUrl);
                }

              }

              selectedItem.itemIFrame = "http://localhost:8000/BookReaderDemo/demo-embed-iframe-params-src.html?images=" + bookPagesUrls;

              // save a link to item in INL
              // ===========================
              selectedItem.InlLink = searchResults[i]["@id"];

              let splitArray = selectedItem.InlLink.split("/")
              let itemName = splitArray[splitArray.length - 1];

/*              // a link to INL embeded link
              // ===========================
              selectedItem.itemIFrame = "https://www.nli.org.il/en/UmbracoEmbedPage?docid=" + itemName + "&type=books&vid=NLI&volumeItem=";
*/
              setSelectedItems(prevSelectedItems => [...prevSelectedItems, selectedItem]);
              //console.log("highResPath = " , highResPath) ; 

              numSelectedItems++;
            }
          }
        } else {
          for (let i = 0 ; numSelectedItems <= 5 && i < searchResults.length; i++) {

            // For each element from searchResults (if not online_in_library_only) load its MARK file to get its item params
            // ================================================================================================================
            if (searchResults[i]["http://purl.org/dc/elements/1.1/accessRights"][0]["@value"].localeCompare("online_in_library_only") ) {

              let thumbnailPath = searchResults[i]["http://purl.org/dc/elements/1.1/thumbnail"][0]["@value"]; 

              // Api for MARK file
              // ==================
              const docId = searchResults[i]["http://purl.org/dc/elements/1.1/recordid"][0]["@value"]
              let markXmlPath = "https://eu01.alma.exlibrisgroup.com/view/sru/972NNL_INST?version=1.2&operation=searchRetrieve&recordSchema=marcxml&query=alma.mms_id=" + docId;
              const response = await fetch(markXmlPath)
              const xmlDataText = await response.text();
  //           console.log("xmlDataText = " , xmlDataText)

              // use XMLParser library object to conver XML to json object (include attributes (tags etc.))
              // ===========================================================================================
              const options = {
                ignoreAttributes : false
              };
              const parser = new XMLParser(options);
              let markObj= parser.parse(xmlDataText);
  //           console.log("markObj = " , markObj);

              // Get relavant mark data
              // =========================
              let selectedItem = parseMarkData(markObj);


              // save high res image path
              // ==========================
              let highResPath = thumbnailPath.substring( 0, thumbnailPath.indexOf( "thumbnail" ) );
              highResPath = highResPath.concat("stream&dps_pid=", selectedItem.highResName);

              selectedItem.highResPath = highResPath;

              // save a link to item in INL
              // ===========================
              selectedItem.InlLink = searchResults[i]["@id"];

              setSelectedItems(prevSelectedItems => [...prevSelectedItems, selectedItem]);
              //console.log("highResPath = " , highResPath) ; 

              numSelectedItems++;
            }
          }
        }
      }
    }
  
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);;
  }, [currntUserIndex])
  
  

  if (profilesTagsArray.length == 0 || usersArray.length == 0) {
    return (
      <div className="App">
        <LoadUsersDataFromDB setProfilesTagsArray={setProfilesTagsArray} setUsersArray={setUsersArray} setCurrntUserIndex={setCurrntUserIndex}> userProfileTagsArray={userProfileTagsArray}</LoadUsersDataFromDB>
      </div>
    ); 
  } else {

    if (true) {
      if (!viewStage.localeCompare("showHomePage") && userProfileTagsArray.length != 0) {
        return (
          <HomePage context={context} handleMouseEnterIndex={handleMouseEnterIndex} setMouseEnableClickIndex={setMouseEnableClickIndex} 
                    homePageImages={homePageImages} userProfileTagsArray={userProfileTagsArray} user={usersArray[currntUserIndex]} handleClick={handleClick} 
                    handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave}> </HomePage>
        )
      } else {
        if (!viewStage.localeCompare("showVerticalCarousel")) {
          return (<VerticalCarousel context={context} libRecommendations={libRecommendations} bookShelf={bookShelf} libNotable={libNotable}
                                  usersArray={usersArray} user={user} showHomePage={showHomePage} showDetailedItem={showDetailedItem}></VerticalCarousel>)
        } else {
          if (!viewStage.localeCompare("showDetailedItem")) {
            return (<ItemDetails detailedItem={detailedItem} libRecommendations={libRecommendations} usersArray={usersArray} showHomePage={showHomePage} showVerticalCarousel={showVerticalCarousel}></ItemDetails>)
          }
        }
      }

 /*     return (

          <div>
          {selectedItems.map(selectedItem => 
           <div>
              
              <iframe class="bookreader-wrapper" src={selectedItem.itemIFrame}></iframe>

           </div>
    
          )}
          </div>
        
          ); */

    }
  }
}

export default App;
