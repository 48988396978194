import React, { useEffect , useState } from 'react';
import { projectFirestore} from './firebase/config';

const LoadUsersDataFromDB = props => {
  const { setProfilesTagsArray , setUsersArray , setCurrntUserIndex} = props

  useEffect(() => {

    const fetchData = async() => {

        try {

        // Load profilesTags for DB
        // ==========================
        const querySnapshot1 = await projectFirestore.collection("profilesTags").get();
        let profilesTagsArray =[];
        let i=0;
        querySnapshot1.forEach((doc) => {
            profilesTagsArray.push({...doc.data() ,id: doc.id});
            console.log("profilesTagsArray => ", profilesTagsArray[i]);
            i++;
            });

        // Load users for DB
        // ==================
        const querySnapshot2 = await projectFirestore.collection("user2").get();
        let usersArray =[];
        i=0;
        querySnapshot2.forEach((doc) => {
            usersArray.push({...doc.data() ,id: doc.id});
            console.log("usersArray => ", usersArray[i]);
            i++;
        });




        setProfilesTagsArray(profilesTagsArray);
        setUsersArray(usersArray);
        setCurrntUserIndex(1);

        } catch(err) {
            console.error(err);
        }

    };

    fetchData();

  }, []);

  return (null);

};
export default LoadUsersDataFromDB;
