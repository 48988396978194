/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'


const HomePage = ({ context , handleMouseEnterIndex , setMouseEnableClickIndex , homePageImages , userProfileTagsArray , user, handleClick, handleMouseEnter , handleMouseLeave}) => {

    const ref = React.useRef(null);

    const [firstUnderline, setFirstUnderline] = useState(false);
    const getHeight = () => window.innerHeight;

// ===================================================================================================
 useEffect(() => {
// ===================================================================================================
  
    const elm = ref.current;
    if (elm != null) {

        if (!context.localeCompare("Bialik" || context == "")) {
            handleMouseEnterIndex(0 , '0');
            setMouseEnableClickIndex(0);

        } else {
            handleMouseEnterIndex(1 , '1');
            setMouseEnableClickIndex(1);
        } 


//      elm.classList.add("textUnderline");
    } else {
      setFirstUnderline(!firstUnderline);
    }
     
    }, [firstUnderline , context]);
    
  
return (
    <div className="base-feature-inner topic-feature-inner">

        <div className='inlLogo inlLogoBlack' ></div>
        <div className='bookTrailText'> Booktrail</div>
        <div className="topic-context-container">
            <div className="topic-context">{context}</div>
        </div>
        <div className="topic-context-container1">
            <div className="topic-context-button">DISCOVER</div>
        </div>

        <div className='bookTrailLang'> ע | E | ع</div>
        <div className='bookTrailMenu'> Menu</div>

        <div className='bookTrailUserName'>{user.name}</div>

        <div className="base-feature__text topic-feature__text" style={{
                      height: getHeight() + "px"
                    }}>


            <div className="topic-feature__subheader-container">
                <div className="topic-feature__subheading">Discovered for you in...</div>
            </div>

            <div className="topic-feature__subheader-container1">
                <div className="topic-feature__subheading1">Get personalized recommendations, follow topics, share ideas.</div>
            </div>

            <ul className="topic-feature__list">

                <li className="topic-feature__item"
                                onClick={handleClick}
                                onMouseOut={handleMouseLeave}
                                onMouseOver={ handleMouseEnter}
                                key="100"
                                ><a  ref={ref} id="0"  className="topic-feature__item--link">{userProfileTagsArray[0].tagName}</a></li>
                    
                {userProfileTagsArray.slice(1).map((userProfileTags, index) => 

                        <li className="topic-feature__item"
                            onClick={handleClick}
                            onMouseOut={handleMouseLeave}
                            onMouseOver={ handleMouseEnter}
                            key={index.toString()}
                            ><a  id={index + 1}  className="topic-feature__item--link">{userProfileTags.tagName}</a></li>
                        )}

            </ul>


    </div> 

    <div className="topic-feature__image-container" style={{
                      height: getHeight() + "px"
                    }}>

        <div className="topic-feature__image-container-inner">

            {userProfileTagsArray.map((userProfileTags, index) => 

                <figure id={homePageImages[index].id} className="topic-feature__figure">
                <img src={userProfileTags.homeImageUrl} alt="Infrastructure" className="topic-feature__image"/>
                </figure>

            )}
</div> 
    </div> 
  </div> 
)

}



export default HomePage
