/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import MediaItem from './MediaItem.js'

const getWidth = () => window.innerWidth

const ItemCommunityPage = ({ usersArray , onWheel , isActive , isVisable , determinePlacement , itemHeight , i}) => {
    
return (

    <div className='itemContainer'>

        <div 

            onWheel = {(e) => onWheel(e)}
            className = {"carousel-item" + (isActive(i) ? " active" : "") +  (isVisable(i) ? " visible" : "")}
            key={i}
            style={{
              transform: `translateY(${determinePlacement(i)}px)`,
              transitionDuration: "1s",
              height: itemHeight + "px",
              top: 0,
              width: "100vw",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              overflow: "hidden",
              color: "black"

            }}
            >


            <div className="communityImagesContainer">

            {usersArray.map((userObj, i) => 

                <div className="communityImageContainer">
                    <div className="communityImage">
                        <img className="likedByImage" src={userObj.userImageUrl}/>
                        <div className="communityUserName"> {userObj.name} </div>
                    </div>
                </div>

            )}

            </div>

        </div>
    </div>


   )

}



export default ItemCommunityPage
