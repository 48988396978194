import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as Next } from "./assets/chevronDown.svg";
import { ReactComponent as Prev } from "./assets/chevronUp.svg";
import "./verticalCarousel.css";
import ItemCarousel from './ItemCarousel.js'

const carouselPagesTypes1 = [{type:"ItemPartPage" , numItems : 1} , 
                            
{type:"ItemPartPage" , numItems : 1} , 
{type:"ItemTowPage" , numItems : 2} , 
{type:"ItemFullPage" , numItems : 1} , 
{type:"ItemFullPage" , numItems : 1} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemTowPage" , numItems : 2} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemTowPage" , numItems : 2} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemPartPage" , numItems : 2} , 
{type:"ItemPartPage" , numItems : 1} ];

const carouselPagesTypes2 = [
  {type:"ItemTowPage" , numItems : 2} , 
{type:"ItemTowPage" , numItems : 2} , 
{type:"ItemPartPage" , numItems : 1} , 
{type:"ItemTowPage" , numItems : 2} , 
{type:"ItemTowPage" , numItems : 2} , 
{type:"ItemTowPage" , numItems : 2} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemTowPage" , numItems : 2} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemPartPage" , numItems : 1} ,
{type:"ItemPartPage" , numItems : 2} , 
{type:"ItemPartPage" , numItems : 1} ];
                            
                            
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-vertical-carousel-component-in-react
 */
const getHeight = () => window.innerHeight;
const animationBreak = 400 //750;


const VerticalCarousel = ({context , libRecommendations , bookShelf , libNotable , usersArray , user , showHomePage , showDetailedItem}) => {

  let lastItemIndex = libRecommendations.length - 1;
  let carouselPagesTypesArray = [];

  let carouselPagesTypes=[];
  if (!context.localeCompare("Bialik")) {
    carouselPagesTypes = carouselPagesTypes1;
  } else {
    carouselPagesTypes = carouselPagesTypes2;
  }

  for (let i = 0 , firstItemIndex = 0 ; i < carouselPagesTypes.length ; i++) {
    if (i == 0) {
      carouselPagesTypes[i].firstItemIndex = 0;
      carouselPagesTypesArray.push(carouselPagesTypes[i]);
    } else {
      firstItemIndex += carouselPagesTypes[i-1].numItems;
      if (firstItemIndex <= lastItemIndex) {
        carouselPagesTypes[i].firstItemIndex = firstItemIndex;
        carouselPagesTypesArray.push(carouselPagesTypes[i]);
      } else {
        break;
      }
    }
  }

  // sort libRecommendations by media types and vertical scoller pages types
  // ========================================================================
  let libRecommendationsSorted = [];
  let libRecommendations1 = [];
  

  for (let libRecommendationIndex = 0 ; libRecommendationIndex < libRecommendations.length ; libRecommendationIndex++) {
    libRecommendations1.push({...libRecommendations[libRecommendationIndex] ,sorted: false})
  }


  for (let carouselPagesTypeIndex = 0 ; carouselPagesTypeIndex < carouselPagesTypesArray.length ; carouselPagesTypeIndex++) {

    // for "ItemPartPage"
    // ==================
    if (!carouselPagesTypesArray[carouselPagesTypeIndex].type.localeCompare("ItemPartPage")) {
      let sorted = false;
      for (let libRecommendationIndex = 0 ; libRecommendationIndex < libRecommendations1.length && !sorted; 
          libRecommendationIndex++) {
          // If item was not sorted yet
          // ===========================
        if (!libRecommendations1[libRecommendationIndex].sorted) {
          // First look for books
          // =====================
          if (!libRecommendations1[libRecommendationIndex].media.localeCompare("book")) {
            libRecommendations1[libRecommendationIndex].sorted = true;
            libRecommendationsSorted.push({...libRecommendations1[libRecommendationIndex] ,sorted: true});
            sorted = true;
          }
        }
      }
      if (!sorted) {
        for (let libRecommendationIndex = 0 ; libRecommendationIndex < libRecommendations1.length && !sorted; 
          libRecommendationIndex++) {
          // If item was not sorted yet
          // ===========================
          if (!libRecommendations1[libRecommendationIndex].sorted) {
            if (!libRecommendations1[libRecommendationIndex].format.localeCompare("part")) {
              libRecommendations1[libRecommendationIndex].sorted = true;
              libRecommendationsSorted.push({...libRecommendations1[libRecommendationIndex] ,sorted: true});
              sorted = true;
            }
          }
        }
      }
    } else {
      // for "ItemPartPage"
      // ==================
      if (!carouselPagesTypesArray[carouselPagesTypeIndex].type.localeCompare("ItemFullPage")) {
        for (let sorted = false , libRecommendationIndex = 0 ; libRecommendationIndex < libRecommendations1.length && !sorted; libRecommendationIndex++) {
          // If item was not sorted yet
          // ===========================
          if (!libRecommendations1[libRecommendationIndex].sorted && !libRecommendations1[libRecommendationIndex].format.localeCompare("full")) {
            libRecommendations1[libRecommendationIndex].sorted = true;
            libRecommendationsSorted.push({...libRecommendations1[libRecommendationIndex] ,sorted: true});
            sorted = true;
          }
        }
      } else {
        if (!carouselPagesTypesArray[carouselPagesTypeIndex].type.localeCompare("ItemTowPage")) {
          let sorted = false;
          for (let libRecommendationIndex = 0 ; libRecommendationIndex < libRecommendations1.length && !sorted; 
            libRecommendationIndex++) {
            // If item was not sorted yet
            // ===========================
            if (!libRecommendations1[libRecommendationIndex].sorted && !libRecommendations1[libRecommendationIndex].format.localeCompare("1of2")) {
              libRecommendations1[libRecommendationIndex].sorted = true;
              libRecommendationsSorted.push({...libRecommendations1[libRecommendationIndex] ,sorted: true});

              for (let libRecommendationIndex1 = libRecommendationIndex + 1 ; libRecommendationIndex1 < libRecommendations1.length && !sorted; libRecommendationIndex1++) {
                // If item was not sorted yet
                // ===========================
                if (!libRecommendations1[libRecommendationIndex1].sorted && !libRecommendations1[libRecommendationIndex1].format.localeCompare("1of2")) {
                  libRecommendations1[libRecommendationIndex1].sorted = true;
  
                  libRecommendationsSorted.push({...libRecommendations1[libRecommendationIndex1] ,sorted: true});
                  sorted = true;
                }
              }
            }
          }
        }
      }
    }
  }


  carouselPagesTypesArray.push({type:"ItemLibNotable" , numItems : 4});
  carouselPagesTypesArray.push({type:"ItemCommunityPage" , numItems : 4});
  carouselPagesTypesArray.push({type:"ItemShelfPage" , numItems : 3});
  

  console.log("carouselPagesTypesArray = " , carouselPagesTypesArray);


  const [activeIndex, setActiveIndex] = useState(0);
  const [lastTime, setLastTime] = useState(0);
  const [inlLogoClassColor, setInlLogoClassColor] = useState("inlLogoBlack");
  const [upArrowClassColor, setUpArrowClassColor] = useState("upArrowBlack");
  const [downArrowClassColor, setDownArrowClassColor] = useState("downArrowBlack");
  
  const [bookTrailTextColor, setBookTrailTextColor] = useState("bookTrailTextColorBlack");
  const [bookShelfTextVisable, setBookShelfTextVisable] = useState("bookShelfTextInVisable");
  const [communityTextVisable, setCommunityTextVisable] = useState("communityTextInVisable");
  
  
  const [bookActionVisable, setBookActionVisable] = useState("bookActionVisable");
  
  
  const [bookLikeColor, setBookLikeColor] = useState("bookLikeOffBlack");
  const [bookSaveColor, setBookSaveColor] = useState("bookSaveOffBlack");
  const [bookDisLikeColor, setBookDisLikeColor] = useState("bookDisLikeOffBlack");
  
  

   // ===================================================================================================
   const isVisable = (i) => {
    // ===================================================================================================
    if (activeIndex === i) return true;

    }

   // ===================================================================================================
   const isActive = (i) => {
    // ===================================================================================================
      return activeIndex === i;
   }

  // Used to determine which items appear above the active itemll;
  /*const halfwayIndex = Math.ceil(libRecommendations.length / 2); */

  // Usd to determine the height/spacing of each item
  const itemHeight = getHeight();  // 52;

  const determinePlacement = (itemIndex) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= carouselPagesTypesArray.length || itemIndex < 0) {
      return
    }
    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };

   // ===================================================================================================
   const setColorClasss = (carouselPageTypeObj) => {
    // ===================================================================================================
    if (!carouselPageTypeObj.type.localeCompare("ItemFullPage") || !carouselPageTypeObj.type.localeCompare("ItemFourPage")) { // || 
 //   !carouselPageTypeObj.type.localeCompare("ItemLibNotable") ) {
      setInlLogoClassColor("inlLogoWhite");
      setUpArrowClassColor("upArrowWhite");
      setDownArrowClassColor("downArrowWhite");

      setBookTrailTextColor("bookTrailTextColorWhite");
      setBookLikeColor("bookLikeOffWhite")
      setBookSaveColor("bookSaveOffWhite")
      setBookDisLikeColor("bookDisLikeOffWhite")

    } else {
      setInlLogoClassColor("inlLogoBlack");
      setUpArrowClassColor("upArrowBlack");
      setDownArrowClassColor("downArrowBlack");
      setBookTrailTextColor("bookTrailTextColorBlack");
      setBookLikeColor("bookLikeOffBlack")
      setBookSaveColor("bookSaveOffBlack")
      setBookDisLikeColor("bookDisLikeOffBlack")

    }


    if (!carouselPageTypeObj.type.localeCompare("ItemShelfPage") || !carouselPageTypeObj.type.localeCompare("ItemCommunityPage")) {
      setBookActionVisable("bookActionInVisable");

      if (!carouselPageTypeObj.type.localeCompare("ItemShelfPage")) {
        setBookShelfTextVisable("bookShelfTextVisable");
        setCommunityTextVisable("communityTextInVisable");
      } else {
        setCommunityTextVisable("communityTextVisable");
        setBookShelfTextVisable("bookShelfTextInVisable");
      }

    } else {
      setBookShelfTextVisable("bookShelfTextInVisable");
      setCommunityTextVisable("communityTextInVisable");
      setBookActionVisable("bookActionVisable");
    }
    
  } 

// ===================================================================================================
const showNextPage = () => {
// ===================================================================================================
  const currentTime = new Date().getTime();
  const isAnimationEnable = currentTime - lastTime > animationBreak; 

  if (isAnimationEnable) {
    if (activeIndex + 1 > carouselPagesTypesArray.length - 1) {
      return;
    }
    setLastTime(currentTime);
    setActiveIndex((prevIndex) => {
      if (prevIndex + 1 > carouselPagesTypesArray.length - 1) {
        setColorClasss(carouselPagesTypesArray[0]);
        return 0;
      }
      setColorClasss(carouselPagesTypesArray[prevIndex + 1]);
      return prevIndex + 1;        
    });
  };
}

// ===================================================================================================
const showPrevPage = () => {
  // ===================================================================================================
    const currentTime = new Date().getTime();
    const isAnimationEnable = currentTime - lastTime > animationBreak; 
  
    if (isAnimationEnable) {
      if (activeIndex == 0) {
        return;
      }
      setLastTime(currentTime);
      setActiveIndex((prevIndex) => {
        if (prevIndex - 1 < 0) {
          setColorClasss(carouselPagesTypesArray[carouselPagesTypesArray.length - 1]);
          return carouselPagesTypesArray.length - 1;
        }
        setColorClasss(carouselPagesTypesArray[prevIndex - 1]);
        return prevIndex - 1;
    });
    };
  }


// ===================================================================================================
const onWheel = (e) => {
// ===================================================================================================

  const currentTime = new Date().getTime();
  const isAnimationEnable = currentTime - lastTime > animationBreak; 

  if (isAnimationEnable) {

    if (((e.deltaY > 0 && activeIndex + 1 > carouselPagesTypesArray.length - 1) || (e.deltaY < 0 &&  activeIndex == 0))) {
      return;
    }

   //   console.log(e);
      setLastTime(currentTime);
      setActiveIndex((prevIndex) => {
        if (e.deltaY > 0) { // next
          if (prevIndex + 1 > carouselPagesTypesArray.length - 1) {
            setColorClasss(carouselPagesTypesArray[0]);
            return 0;
          }
          setColorClasss(carouselPagesTypesArray[prevIndex + 1]);
          return prevIndex + 1;
        } 
        if (prevIndex - 1 < 0) {
          setColorClasss(carouselPagesTypesArray[carouselPagesTypesArray.length - 1]);
          return carouselPagesTypesArray.length - 1;
        }
        setColorClasss(carouselPagesTypesArray[prevIndex - 1]);
        return prevIndex - 1;
        
    });
  }
};


// ===================================================================================================
const handleClick = (direction) => {
// ===================================================================================================
 setActiveIndex((prevIndex) => {
      if (direction === "next") {
        if (prevIndex + 1 > carouselPagesTypesArray.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return carouselPagesTypesArray.length - 1;
      }

      return prevIndex - 1;
    });
  };



  return (
    <div className="container">
      <section className="outer-container">
        <div className="carousel-wrapper">

          <div className="carousel">
            <div className="slides">

            <div className= {`inlLogo ${inlLogoClassColor}`}  onClick={() => showHomePage()}></div>    
            <div className={`bookTrailText ${bookTrailTextColor}`}> Booktrail</div>
            <div className={`bookShelfText ${bookShelfTextVisable}`}> BookShelf </div>
            <div className={`bookShelfText ${communityTextVisable}`}> Community </div>
            <div className={`bookTrailLang ${bookTrailTextColor}`}> ע | E | ع</div>
            <div className={`bookTrailMenu ${bookTrailTextColor}`}> Menu</div>
            <div className={`bookTrailUserName ${bookTrailTextColor}`}>{user.name}</div>

            <div className= {`upDownArrow upArrow ${upArrowClassColor}`}  onClick={() => showPrevPage()}></div>    
            <div className= {`upDownArrow downArrow ${downArrowClassColor}`}  onClick={() => showNextPage()}></div>    

            
            <div className={`bookAction bookLike ${bookActionVisable} ${bookLikeColor}`}> </div>
            <div className={`bookAction bookSave  ${bookActionVisable} ${bookSaveColor}`}> </div>
            <div className={`bookAction bookDisLike  ${bookActionVisable} ${bookDisLikeColor}`}> </div>

              <div className="carousel-inner" style={{
                      height: itemHeight + "px"
                    }}>

                {carouselPagesTypesArray.map((carouselPageTypeObj, i) => 
                  <ItemCarousel libRecommendations={libRecommendationsSorted} bookShelf={bookShelf} libNotable={libNotable} usersArray={usersArray} user={user}
                    firstItemIndex={carouselPageTypeObj.firstItemIndex} itemCarouselType={carouselPageTypeObj.type}
                    showHomePage={showHomePage} showDetailedItem={showDetailedItem} onWheel={onWheel} 
                    isActive={isActive} isVisable={isVisable} determinePlacement={determinePlacement} itemHeight={itemHeight} i = {i}>
                  </ItemCarousel> 
                  )}

              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  );
};

/* VerticalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  leadingText: PropTypes.string.isRequired
}; */

export default VerticalCarousel;
