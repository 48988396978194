/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import MediaItem from './MediaItem.js'

const getWidth = () => window.innerWidth

const ItemTowPage = ({ libRecommendations , firstItemIndex , showDetailedItem , onWheel, setActiveIndex , isActive , isVisable , determinePlacement , itemHeight , i}) => {
    
return (

    <div className='itemContainer'>

        <div 

            onWheel = {(e) => onWheel(e)}
            onClick={() => setActiveIndex(i)}
            className = {"carousel-item" + (isActive(i) ? " active" : "") +  (isVisable(i) ? " visible" : "")}
            key={i}
            style={{
              transform: `translateY(${determinePlacement(i)}px)`,
              transitionDuration: "1s",
              height: itemHeight + "px",
              top: 0,
              width: "100vw",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              overflow: "hidden",
              color: "black"

            }}
            >
            <div className=' TowItemsPageColumn1'
                        onClick={() => showDetailedItem(libRecommendations[firstItemIndex])}
            >
                <div className=' TowItemsPageColumnContainer1'>

                    <div className='itemTitleText itemTitleText2Left'> {libRecommendations[firstItemIndex].title}
                        <span className="mediaText"> {libRecommendations[firstItemIndex].media}</span>
                    </div>
                    <MediaItem item={libRecommendations[firstItemIndex]} className ="TowItemsPageImage"></MediaItem>

                </div>
            </div>

            <div className='TowItemsPageColumn2'
                onClick={() => showDetailedItem(libRecommendations[firstItemIndex + 1])}
            >
                <div className=' TowItemsPageColumnContainer2'>
            
                    <div className='itemTitleText itemTitleText2Right'> {libRecommendations[firstItemIndex + 1].title}
                        <span className="mediaText"> {libRecommendations[firstItemIndex + 1].media}</span>
                    </div>
                    <MediaItem item={libRecommendations[firstItemIndex + 1]} className ="TowItemsPageImage"></MediaItem>

                </div>
            </div>


        </div>
    </div>


   )

}



export default ItemTowPage
