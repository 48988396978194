import firebase from 'firebase/compat/app';

import 'firebase/compat/storage';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyCoW4arLzsUNBnLAS9Lm3a7P8zNxaGiz-0",
    authDomain: "booktrail-8cdfa.firebaseapp.com",
    projectId: "booktrail-8cdfa",
    storageBucket: "booktrail-8cdfa.appspot.com",
    messagingSenderId: "1026877024029",
    appId: "1:1026877024029:web:2322293f200dd80b9ca3f4"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };