/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import MediaItem from './MediaItem.js'

const itemHeight = window.innerHeight;
const conversationText = "Welcome to BookTrail comments! please keep converstation coureteous and <br> on-topic. See our community guidelines for more information";


const ItemDetails = ({ detailedItem , libRecommendations ,usersArray , showHomePage , showVerticalCarousel}) => {
   

  const [likedByUserArray, setLikedByUserArray] = useState([]);
  const [related, setRelated] = useState([]);

  // ===================================================================================================
const openItemInInl = (url) => {
  // ===================================================================================================

  window.open(
    url,
    '_blank' // <- This is what makes it open in a new window.
  );

 }

// ===================================================================================================
  useEffect(() => {
// ===================================================================================================
   
    let theLikedByUserArray = [];
    for (let i = 0 ; i < detailedItem.likedBy.length ; i++) {
      const likedByUseId = detailedItem.likedBy[i];
      for (let j = 0 ; j < usersArray.length ; j++) {
        if (!usersArray[j].id.localeCompare(likedByUseId)) {
          theLikedByUserArray.push(usersArray[j]);
          break;
        }
      }
    }

    setLikedByUserArray(theLikedByUserArray);


    let theRelatedArray = [];
    for (let i = 0 ; i < detailedItem.related.length ; i++) {
      const relatedItemId = detailedItem.related[i];
      for (let j = 0 ; j < libRecommendations.length ; j++) {
        if (!libRecommendations[j].id.localeCompare(relatedItemId)) {
          theRelatedArray.push(libRecommendations[j]);
          break;
        }
      }
    }

    setRelated(theRelatedArray);




  }, []) 

// ===================================================================================================
return (
// ===================================================================================================

        <div className='itemDetailedContainer' style={{
            height: itemHeight + "px"
          }}>

            <div className= {`inlLogo inlLogoBlack`}  onClick={() => showHomePage()}></div>    
            <div className={`bookTrailText bookTrailTextColorBlack`}> Booktrail</div>            
            <div className={`bookAction bookLike bookLikeOffBlack`}> </div>
            <div className={`bookAction bookSave bookSaveOffBlack`}> </div>
            <div className={`bookAction bookDisLike bookDisLikeOffBlack`}> </div>

            <div className= {`closeDetailedPageLogo`}  onClick={() => showVerticalCarousel()}></div>    
            <div className= {`linkNliDetailedPageLogo`}  onClick={() => openItemInInl(detailedItem.inlLink)}>  </div>    
            <div className= {`linkNliDetailedPageText`}  onClick={() => openItemInInl(detailedItem.inlLink)}>Open in NLI </div>    
            

            <div className=' TowItemsPageColumn1'>
                <div className=' TowItemsPageColumnContainer1'>

                    <div className='itemTitleText itemTitleText2Left'> {detailedItem.title}
                        <span className="mediaText"> {detailedItem.media}</span>
                    </div>
                    <MediaItem item={detailedItem} className ="TowItemsPageImage"></MediaItem>

                </div>
            </div>

            <div className='TowItemsPageColumn2 itemDetailedRightContainer'>
              <div className=' itemsDetailsContainer2'>

                <div className= 'itemsDetailsTitle'> {detailedItem.title} </div>
                <div className= 'itemsDetailsText' dangerouslySetInnerHTML={{ __html: detailedItem.detailedText }} />          

                <div className="likedByText"> Liked by</div>

                {likedByUserArray.map((likedByUserObj, i) => 

                  <div className="likedByImageInfoContainer">
                    <div className="likedByImageContainer">
                      <img className="likedByImage" src={likedByUserObj.userImageUrl}/>
                    </div>
                  </div>

                )}

                <div className='clearAllAll'></div>

                <div className="likedByText"> Related </div>


                {related.map((relatedObj, i) => 

                  <div className="likedByImageInfoContainer">
                    <div className="likedByImageContainer">
                      <img className="relatedImage" src={relatedObj.imageUrl}/>
                    </div>
                  </div>

                  )}

              <div className='clearAllAll'></div>

              <div className="likedByText"> Conversation </div>
              <div className="itemsDetailsText"> {conversationText} </div>

              <div className="conversation-login"> Log in | Sign up </div>
              <div className='clearAllAll'></div>

              <div className="conversation-container">
                <div className="conversation">Be the first to comment</div>
              </div>
             
              </div>
            </div>


        </div>
      
      
      
         )
      
};

/* VerticalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  leadingText: PropTypes.string.isRequired
}; */

export default ItemDetails;
