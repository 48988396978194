/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'

const getWidth = () => window.innerWidth

const MediaItem = ({ item , className}) => {
    
  if (!item.media.localeCompare("book")) {
    return (
      <iframe className={className} src={item.itemIFrame}></iframe>

    );
  } else {
    return (
      <img className={className} src={item.imageUrl}/>

    );
  }

}



export default MediaItem
