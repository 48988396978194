/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import MediaItem from './MediaItem.js'

const getWidth = () => window.innerWidth

const ItemLibNotable = ({ libNotable , onWheel , isActive , isVisable , determinePlacement , itemHeight , i}) => {
    
return (

    <div className='itemContainer'>

        <div 

            onWheel = {(e) => onWheel(e)}
            className = {"carousel-item" + (isActive(i) ? " active" : "") +  (isVisable(i) ? " visible" : "")}
            key={i}
            style={{
              transform: `translateY(${determinePlacement(i)}px)`,
              transitionDuration: "1s",
              height: itemHeight + "px",
              top: 0,
              width: "100vw",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              overflow: "hidden",
              color: "white"

            }}
            >
            <div className='FourItemsPageColumn'>
            
                <MediaItem item={libNotable[0]} className ="TowItemsPageImage"></MediaItem>
                <div className='libNotableBotton'> #{libNotable[0].tagName}</div>

            </div>

            <div className='FourItemsPageColumn'>
            
                <MediaItem item={libNotable[1]} className ="TowItemsPageImage"></MediaItem>
                <div className='libNotableBotton'> #{libNotable[1].tagName}</div>


            </div>

            <div className='FourItemsPageColumn'>
            
                <MediaItem item={libNotable[2]} className ="TowItemsPageImage"></MediaItem>
                <div className='libNotableBotton'> #{libNotable[2].tagName}</div>

            </div>

            <div className='FourItemsPageColumn'>
            
                <MediaItem item={libNotable[3]} className ="TowItemsPageImage"></MediaItem>
                <div className='libNotableBotton'> #{libNotable[3].tagName}</div>


            </div>

        </div>
    </div>


   )

}



export default ItemLibNotable
