/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import ItemFullPage from './ItemFullPage.js'
import ItemPartPage from './ItemPartPage.js'
import ItemTowPage from './ItemTowPage.js'
import ItemLibNotable from './ItemLibNotable.js'
import ItemShelfPage from './ItemShelfPage.js'
import ItemCommunityPage from './ItemCommunityPage.js'


const ItemCarousel = ({ libRecommendations , bookShelf , libNotable , usersArray , user , firstItemIndex , itemCarouselType , showHomePage , showDetailedItem , onWheel , isActive , isVisable , determinePlacement , itemHeight , i}) => {
    
  if (!itemCarouselType.localeCompare("ItemFullPage")) {
    return (
        <ItemFullPage libRecommendations={libRecommendations} firstItemIndex={firstItemIndex}
        showHomePage={showHomePage} showDetailedItem={showDetailedItem} onWheel={onWheel} 
        isActive={isActive} isVisable={isVisable} determinePlacement={determinePlacement} itemHeight={itemHeight}
        i = {i}></ItemFullPage>
    ) 
  } else {
    if (!itemCarouselType.localeCompare("ItemTowPage")) {
      return (

        <ItemTowPage libRecommendations={libRecommendations} firstItemIndex={firstItemIndex}
        showHomePage={showHomePage} showDetailedItem={showDetailedItem} onWheel={onWheel} 
        isActive={isActive} isVisable={isVisable} determinePlacement={determinePlacement} itemHeight={itemHeight}
        i = {i}></ItemTowPage>
      )

    } else {
      if (!itemCarouselType.localeCompare("ItemPartPage")) {
        return (
        <ItemPartPage libRecommendations={libRecommendations} firstItemIndex={firstItemIndex}
        showHomePage={showHomePage} showDetailedItem={showDetailedItem} onWheel={onWheel} 
        isActive={isActive} isVisable={isVisable} determinePlacement={determinePlacement} itemHeight={itemHeight}
        i = {i}></ItemPartPage>
        )
      } else {
        if (!itemCarouselType.localeCompare("ItemLibNotable")) {
          return (
          <ItemLibNotable libNotable={libNotable} firstItemIndex={firstItemIndex}
          showHomePage={showHomePage} showDetailedItem={showDetailedItem} onWheel={onWheel} 
          isActive={isActive} isVisable={isVisable} determinePlacement={determinePlacement} itemHeight={itemHeight}
          i = {i}></ItemLibNotable>
          )
        }  else {
          if (!itemCarouselType.localeCompare("ItemCommunityPage")) {
            return (
            <ItemCommunityPage   usersArray={usersArray} onWheel={onWheel} 
            isActive={isActive} isVisable={isVisable} determinePlacement={determinePlacement} itemHeight={itemHeight}
            i = {i}></ItemCommunityPage>
            )
          }  else {
            if (!itemCarouselType.localeCompare("ItemShelfPage")) {
              return (
              <ItemShelfPage bookShelf={bookShelf} user={user} firstItemIndex={firstItemIndex}
              showHomePage={showHomePage} showDetailedItem={showDetailedItem} onWheel={onWheel} 
              isActive={isActive} isVisable={isVisable} determinePlacement={determinePlacement} itemHeight={itemHeight}
              i = {i}></ItemShelfPage>
              ) 
            }
          }
        }
      }
    } 
  }
};

/* VerticalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  leadingText: PropTypes.string.isRequired
}; */

export default ItemCarousel;
