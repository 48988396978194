/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import './App.css';
import React, { useState, useEffect} from 'react'
import MediaItem from './MediaItem.js'


const getWidth = () => window.innerWidth

const ItemFullPage = ({ libRecommendations , firstItemIndex , showDetailedItem , onWheel , isActive , isVisable , determinePlacement , itemHeight , i}) => {
      
return (

    <div className='itemContainer'>
        
        <div 

            onWheel = {(e) => onWheel(e)}
            onClick={() => showDetailedItem(libRecommendations[firstItemIndex])}
            className = {"carousel-item" + (isActive(i) ? " active" : "") +  (isVisable(i) ? " visible" : "")}
            key={i}
            style={{
              transform: `translateY(${determinePlacement(i)}px)`,
              transitionDuration: "1s",

              height: itemHeight + "px",
              backgroundImage: `url(${libRecommendations[firstItemIndex].imageUrl})`,
              top: 0,
              width: "100vw",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              overflow: "hidden",
              color: "white"
  
            }}

            >
        <div className='itemTitleText itemTitleText2Left'> {libRecommendations[firstItemIndex].title}
          <span className="mediaText"> {libRecommendations[firstItemIndex].media}</span>
        </div>
        <MediaItem item={libRecommendations[firstItemIndex]} className ="galleryImage"></MediaItem>

        </div>
    </div>


   )

}



export default ItemFullPage
